import { ParticipantCategory } from 'types';

export const getParticipantCategory = (category: ParticipantCategory): string => {
  switch (category) {
    case ParticipantCategory.IndividualStudent:
      return 'Individual student';
    case ParticipantCategory.TeamOfStudents:
      return 'Team of students';
    case ParticipantCategory.IndividualProfessional:
      return 'Individual professional';
    case ParticipantCategory.CompanyGreaterThan10Employees:
      return 'Company > 10 employees';
    case ParticipantCategory.CompanyGreater11100Employees:
      return 'Company 11 - 100 employees';
    case ParticipantCategory.CompanyGreaterThan100Employees:
      return 'Company 100+ employees';
  }
};
