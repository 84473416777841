import React from 'react';
import styled from 'styled-components';

import { apiUrls, colors, mediaBase, mediaQueries } from 'variables';
import { useParams } from 'react-router-dom';
import { Loader } from 'components/common/Loader';
import { apiClient } from 'helpers/APIClient';
import { toast } from 'react-toastify';
import { toastSettings } from 'helpers/toastSettings';
import { getParticipantCategory } from 'helpers/getParticipantCategory';
import { PhotoModal } from 'components/modals/PhotoModal';

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 36px 25px;

  ${mediaQueries.lg} {
    padding: 36px 50px;
    flex-direction: row;
    justify-content: space-between;
  }

  ${mediaQueries.xl} {
    padding: 36px 88px;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mediaQueries.lg} {
    width: 50%;
    padding-right: 54px;
  }
`;

const Images = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 48px;

  ${mediaQueries.lg} {
    width: 50%;
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 16px;
  cursor: pointer;
`;

const PrimaryText = styled.p`
  margin: 2px 0;
  font-size: 18px;
`;

const DescriptionWrapper = styled.div`
  color: ${colors.secondary};
  margin: 52px 0;
`;

const DetailTitle = styled.span`
  color: white;
`;

const Description = styled.p`
  margin-top: 0;
`;

const Credits = styled.p`
  word-break: break-word;
  margin: 0 0 36px;
`;

export const ProjectDetails = (): JSX.Element => {
  const params = useParams<{ id: string | undefined }>();
  const projectId = params.id;

  // eslint-disable-next-line
  const [project, setProject] = React.useState<any | null>(null);
  const [modalPicture, setModalPicture] = React.useState<string | null>(null);

  React.useEffect(() => {
    apiClient
      .get(`${apiUrls.projectDetails(projectId)}`)
      .then(({ data }) => setProject(data))
      .catch(() => toast('Unable to get project details', toastSettings));
  }, []);

  if (!project) return <Loader />;

  return (
    <Wrapper>
      <Details>
        <PrimaryText>{project.projectName}</PrimaryText>

        <DescriptionWrapper>
          {project.description && <Description>{project.description}</Description>}

          {project.participantCategory && (
            <>
              <DetailTitle>Participant category: </DetailTitle>
              <span>{getParticipantCategory(project.participantCategory)}</span>
              <br />
            </>
          )}

          {project.company && (
            <>
              <DetailTitle>Company name: </DetailTitle>
              <span>{project.company}</span>
              <br />
            </>
          )}

          {project.location && (
            <>
              <DetailTitle>Location: </DetailTitle>
              <span>{project.location}</span>
              <br />
            </>
          )}

          {project.designTeam && (
            <>
              <DetailTitle>Team: </DetailTitle>
              <span>{project.designTeam}</span>
            </>
          )}
        </DescriptionWrapper>
      </Details>
      <Images>
        {project.images.map(({ image, credits }: { image: string; credits?: string }) => (
          <React.Fragment key={image}>
            <ProjectImage
              onClick={() => setModalPicture(`${mediaBase}${image}`)}
              src={`${mediaBase}${image}`}
            />
            {credits && <Credits>Photo credits: {credits}</Credits>}
          </React.Fragment>
        ))}
      </Images>
      {modalPicture && (
        <PhotoModal
          isOpen={Boolean(modalPicture)}
          closeModal={() => setModalPicture(null)}
          picture={modalPicture}
        />
      )}
    </Wrapper>
  );
};
