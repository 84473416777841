import React from 'react';
import styled from 'styled-components';
import { colors, getLabelColor, getLabelText, mediaQueries } from 'variables';
import { useHistory } from 'react-router-dom';
import { DEALabel, DEALabelObject } from 'types';
import truncate from 'truncate';
import capitalize from 'capitalize';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;

  :hover {
    background-color: rgba(255, 255, 255, 0.05);

    button {
      display: block;
    }
  }
`;

const Title = styled.p`
  margin: 8px 0 12px;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
`;

const SecondaryText = styled.p`
  margin: 0;
  color: white;
  text-decoration: underline;
  text-underline-offset: 4px;
  line-height: 22px;
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 8px;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 8px;

  ${mediaQueries.md} {
    height: 270px;
  }

  ${mediaQueries.lg} {
    height: 300px;
  }
`;

const DetailsButton = styled.button`
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: ${colors.background};
  border: none;
  min-width: 150px;
  padding: 12px 0;
  cursor: pointer;

  transition: background-color 0.1s linear;

  :hover {
    color: black;
    background-color: white;
  }
`;

const LabelCircle = styled.div<{ label: DEALabel }>`
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${({ label }) => getLabelColor(label)};
  margin-right: 8px;
  flex-shrink: 0;
  margin-top: 0px;
`;

interface Props {
  projectImage: string;
  projectId: number;
  projectName: string;

  labels: DEALabelObject[];
  description: string;
  category: string;
  index: number;
}

export const ProjectCard: React.FC<Props> = ({
  projectImage,
  projectId,
  projectName,
  labels,
  description,
  category,
  index,
}) => {
  const history = useHistory();
  const sortedLabels = [...labels].sort((a, b) => getHighestLabelWeight(b) - getHighestLabelWeight(a));
  const specialLabel = sortedLabels.find(
    label =>
      label.deaLabel === DEALabel.SolaruxChoice ||
      label.deaLabel === DEALabel.EmergingDesigner ||
      label.deaLabel === DEALabel.Selection,
  );
  const hasExtraLabel = specialLabel && sortedLabels[0].deaLabel !== specialLabel.deaLabel;

  return (
    <>
      <Wrapper
        onClick={e => {
          // eslint-disable-next-line
          // @ts-ignore
          if (e.target.className && !e.target.className.includes('rc-rate')) {
            history.push(`/projects/${projectId}`);
          }
        }}
      >
        <ImageWrapper>
          <ProjectImage src={projectImage} />
          <DetailsButton>More details</DetailsButton>
          {/* <PhotoLabelWrapper>
            {[...labels]
              .sort((a, b) => getHighestLabelWeight(b) - getHighestLabelWeight(a))
              .map(label => (
                <PhotoLabel key={label.deaLabel} label={label.deaLabel}>
                  {label.deaLabel === DEALabel.SolaruxChoice
                    ? 'Solarlux Choice'
                    : label.deaLabel.replaceAll('_', ' ').toLocaleLowerCase()}
                </PhotoLabel>
              ))}
          </PhotoLabelWrapper> */}
        </ImageWrapper>
        <div style={{ paddingRight: '32px' }}>
          <Title>{projectName}</Title>
          <div style={{ display: 'flex' }}>
            <SecondaryText>
              <LabelCircle label={sortedLabels[0].deaLabel} />
              {`${capitalize.words(
                sortedLabels[0].deaLabel !== DEALabel.Selection
                  ? sortedLabels[0].deaLabel.replaceAll('_', ' ').toLocaleLowerCase()
                  : 'Selected',
              )} in 
            ${capitalize.words(category)} 
            ${
              hasExtraLabel
                ? capitalize.words(
                    specialLabel?.deaLabel !== DEALabel.Selection
                      ? `+ ${specialLabel?.deaLabel.replaceAll('_', ' ').toLocaleLowerCase()}`
                      : '+ Selected',
                  )
                : ''
            }
            
            `}
            </SecondaryText>
          </div>
          <p style={{ marginTop: '50px', lineHeight: '24px' }}>
            {truncate(description, index % 2 ? 300 : 200)}
          </p>
        </div>
      </Wrapper>
    </>
  );
};

function getHighestLabelWeight(label: DEALabelObject) {
  if (label.deaLabel === DEALabel.WinnerOfTheYear) return 10;
  if (label.deaLabel === DEALabel.GoldPrize) return 9;
  if (label.deaLabel === DEALabel.SilverPrize) return 8;
  if (label.deaLabel === DEALabel.BronzePrize) return 7;
  if (label.deaLabel === DEALabel.SpecialRecognition) return 6;
  if (label.deaLabel === DEALabel.HonorableMention) return 5;
  if (label.deaLabel === DEALabel.EmergingDesigner) return 4;
  if (label.deaLabel === DEALabel.SolaruxChoice) return 3;
  return 2;
}
